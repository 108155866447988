import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["planOptionContainer", "planIntervalInput"];

  selectPlan(evt) {
    const { interval } = evt.params;
    this.planOptionContainerTargets.forEach((el) => {
      const _interval = el.dataset.buyerSearchSubscribeDialogIntervalParam;
      if (_interval === interval) {
        el.classList.add("is-active");
        this.planIntervalInputTarget.value = interval;
      } else {
        el.classList.remove("is-active");
      }
    });
  }
}
