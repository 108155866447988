import { Controller } from "@hotwired/stimulus";
import { Track } from "../../app/track";

export default class extends Controller {
  static targets = [];

  track(evt) {
    if (evt.target.value) {
      Track.gtagEvent(`Selected ${evt.target.value} application`);
    }
  }
}
