import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon"];

  connect() {
    const direction = this.element.dataset.direction;
    if (direction == "back") {
      if (navigation.canGoBack) {
        this.iconTarget.classList.remove("hidden");
      }
    }
    if (direction == "forward") {
      if (navigation.canGoForward) {
        this.iconTarget.classList.remove("hidden");
      }
    }
  }

  go() {
    const direction = this.element.dataset.direction;
    if (direction == "back") {
      window.history.back();
    }
    if (direction == "forward") {
      window.history.forward();
    }
  }
}
