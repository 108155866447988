import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message", "button", "successMessage"];

  connect() {
    this.element.addEventListener("submit", this.submitInProgress.bind(this));
  }

  submitInProgress() {
    if (this.hasSuccessMessageTarget) {
      this.successMessageTarget.classList.add("hidden");
    }
    this.messageTarget.classList.remove("hidden");
    this.buttonTarget.disabled = true;
  }
}
