import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "label"];

  connect() {
    this.setUi();
  }

  get selectVal() {
    return this.selectTarget.value;
  }

  setUi() {
    this.labelTargets.forEach((label, idx) => {
      if (label.dataset.labelFor == this.selectVal) {
        label.classList.remove("hidden");
      } else {
        label.classList.add("hidden");
      }
    });
    if (!this.selectVal) {
      this.labelTargets[0].classList.remove("hidden");
    }
  }
}
