const uploadProgress = (evt) => {
  const $form = $(evt.target).closest("form");
  if ($form) {
    const $uploadEl = $form.find("[data-js-upload-bar]");
    const $input = $form.find("input:submit");
    const progress = Math.round(evt.detail.progress);
    if ($uploadEl && $uploadEl[0]) {
      $uploadEl[0].innerHTML = `Uploading ${progress}%`;
    } else if ($input) {
      $input.val(`Uploading ${progress}%`);
    }
  }
};

export const setup = function () {
  $(document).on("direct-upload:progress", "form", uploadProgress);
};
