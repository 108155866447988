import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["userList"];

  connect() {}

  toggleList(evt) {
    const isHidden = this.userListTarget.classList.contains("hidden");
    if (isHidden) {
      this.userListTarget.classList.remove("hidden");
    } else {
      this.userListTarget.classList.add("hidden");
    }
  }
}
