import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["switch"];

  connect() {
    this.hiddenCheckBox = this.element.querySelector('input[type="checkbox"]');
    this.hiddenCheckBox.addEventListener("click", this.toggle.bind(this));
    this.setClasses();
  }

  toggle(evt) {
    this.hiddenCheckBox.checked = !this.hiddenCheckBox.checked;
    this.setClasses();
  }

  setClasses() {
    const isEnabled = this.hiddenCheckBox.checked;
    if (isEnabled) {
      this.switchTarget.classList.remove("translate-x-0");
      this.switchTarget.classList.add("translate-x-5");
      this.element.classList.remove("bg-gray-200");
      this.element.classList.add("bg-brand-blue");
    } else {
      this.switchTarget.classList.remove("translate-x-5");
      this.switchTarget.classList.add("translate-x-0");
      this.element.classList.remove("bg-brand-blue");
      this.element.classList.add("bg-gray-200");
    }
  }
}
