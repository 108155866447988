import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navItems"];

  connect() {
    //  const $openTrigger = $("[js-burger-nav-trigger]");
    //  const $closeTrigger = $("[js-burger-nav-close]");
    //  const $trigger = $("[js-burger-nav-trigger]");
    //  const $nav = $("[js-burger-nav]");
    //
    //  $openTrigger.on("click", () => {
    //    $nav.removeClass("hidden");
    //  });
    //
    //  $closeTrigger.on("click", () => {
    //    $nav.addClass("hidden");
    //  });
  }

  open() {
    this.navItemsTarget.classList.remove("hidden");
  }

  close(evt) {
    console.log("debug close");
    this.navItemsTarget.classList.add("hidden");
  }
}
