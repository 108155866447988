import { Controller } from "@hotwired/stimulus";

const PROVIDERS = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "aol.com",
  "hotmail.com",
  "comcast.net",
  "mac.com",
  "icloud.com",
  "me.com",
  "live.ca",
  "live.com",
  "cox.net",
];

export default class extends Controller {
  static targets = ["warning"];

  connect() {
    this.inputEl = this.element.querySelector("input");
  }

  checkWarning() {
    if (!this.element.hasAttribute("data-warn-enabled")) {
      return;
    }
    const value = this.inputEl.value.toLowerCase();
    const isPersonalEmail =
      PROVIDERS.map((p) => value.includes(`@${p}`)).filter((p) => p).length > 0;
    if (isPersonalEmail) {
      this.warningTarget.classList.remove("hidden");
    } else {
      this.warningTarget.classList.add("hidden");
    }
  }
}
