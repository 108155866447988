import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon", "content"];

  toggleContent(event) {
    const { params } = event;
    const { hideToggler } = params;
    console.log(params);
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle("rotate-90");
    }
    this.contentTargets.forEach((e) => {
      e.classList.toggle("hidden");
    });
    if (hideToggler) {
      event.target.classList.toggle("hidden");
    }
  }
}
