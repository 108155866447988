import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["content", "button"];

  connect() {
    useClickOutside(this);
    this.buttonTarget.addEventListener("click", () => {
      this.application.controllers.forEach((controller) => {
        if (controller.identifier === "search-popover") {
          controller.close();
        }
      });
      const isOpen = this.element.classList.contains("open");
      if (isOpen) {
        this.element.classList.remove("open");
      } else {
        this.element.classList.add("open");
      }
    });
  }

  clickOutside(event) {
    this.close();
  }

  close() {
    this.element.classList.remove("open");
  }
}
