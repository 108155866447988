import { Controller } from "@hotwired/stimulus";

function isFuzzyMatch(str, search) {
  let searchIndex = 0;
  for (let char of str) {
    if (char.toLowerCase() === search[searchIndex].toLowerCase()) {
      searchIndex++;
    }
    if (searchIndex === search.length) {
      return true;
    }
  }
  return false;
}

export default class extends Controller {
  static targets = ["search", "option"];

  connect() {
    this.searchTarget.addEventListener("sl-input", this.filter.bind(this));
  }

  filter(evt) {
    const search = this.searchTarget.value;
    this.optionTargets.forEach((option) => {
      const text = option.textContent;
      const isSelected = option.checked;
      if (!search) {
        option.classList.remove("hidden");
        return;
      }
      if (isSelected) {
        option.classList.remove("hidden");
        return;
      }
      if (isFuzzyMatch(text, search)) {
        option.classList.remove("hidden");
      } else {
        option.classList.add("hidden");
      }
    });
  }

  clearAll(evt) {
    this.optionTargets.forEach((option) => {
      option.checked = false;
    });
  }
}
