import { Controller } from "@hotwired/stimulus";
import sparkline from "@fnando/sparkline";

function findClosest(target, tagName) {
  if (target.tagName === tagName) {
    return target;
  }

  while ((target = target.parentNode)) {
    if (target.tagName === tagName) {
      break;
    }
  }

  return target;
}

export default class extends Controller {
  connect() {
    let interactiveValues = this.element.getAttribute(
      "data-interactive-values"
    );
    if (interactiveValues && typeof interactiveValues === "string") {
      interactiveValues = JSON.parse(interactiveValues);
      const options = {
        onmousemove(event, datapoint) {
          const svg = findClosest(event.target, "svg");
          const label = svg.nextElementSibling;
          const date = new Date(datapoint.date)
            .toUTCString()
            .replace(/^.*?, (.*?) \d{2}:\d{2}:\d{2}.*?$/, "$1");

          label.classList.remove("hidden");
          label.textContent = `${date}: ${datapoint.value}`;
          label.style.top = `${event.offsetY}px`;
          label.style.left = `${event.offsetX + 20}px`;
        },
        onmouseout() {
          const svg = findClosest(event.target, "svg");
          const label = svg.nextElementSibling;
          label.classList.add("hidden");
        },
      };
      sparkline(this.element, interactiveValues, options);
    }
  }
}
