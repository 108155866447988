import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list"];

  toggle(evt) {
    const isOpen = !this.listTarget.classList.contains("hidden");
    if (isOpen) {
      this.listTarget.classList.add("hidden");
    } else {
      this.listTarget.classList.remove("hidden");
    }
  }
}
