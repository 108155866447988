import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["faqOpenToggle", "faqCloseToggle", "faqAnswer"];

  connect() {}

  toggle(evt) {
    const isOpen = !this.faqAnswerTarget.classList.contains("hidden");
    if (isOpen) {
      this.faqAnswerTarget.classList.add("hidden");
      this.faqOpenToggleTarget.classList.remove("hidden");
      this.faqCloseToggleTarget.classList.add("hidden");
    } else {
      this.faqAnswerTarget.classList.remove("hidden");
      this.faqOpenToggleTarget.classList.add("hidden");
      this.faqCloseToggleTarget.classList.remove("hidden");
    }
  }
}
