import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "hiddenInput",
    "enterNew",
    "yearUpsellCard",
    "monthCopy",
    "yearCopy",
    "cardInputArea",
    "useSavedCardArea",
    "savedCardArea",
  ];

  connect() {
    this.setRightCopy();
  }

  showEnterNew() {
    this.cardInputAreaTarget.classList.remove("hidden");
    this.savedCardAreaTarget.classList.add("hidden");
    this.useSavedCardAreaTarget.classList.remove("hidden");
  }

  hideEnterNew() {
    this.useSavedCardAreaTarget.classList.add("hidden");
    this.savedCardAreaTarget.classList.remove("hidden");
    this.cardInputAreaTarget.classList.add("hidden");
  }

  selectAnnual(evt) {
    evt.preventDefault();
    const isChecked = evt.target.checked;
    const monthPlanId = evt.target.getAttribute("data-month-plan-id");
    const yearPlanId = evt.target.getAttribute("data-year-plan-id");
    if (isChecked) {
      this.hiddenInputTarget.value = yearPlanId;
    } else {
      this.hiddenInputTarget.value = monthPlanId;
    }
    this.setRightCopy();
    ahoy.track("Selected plan", { plan_id: this.hiddenInputTarget.value });
  }

  setRightCopy() {
    if (this.hiddenInputTarget.value === "month") {
      this.monthCopyTargets.forEach((t) => t.classList.remove("hidden"));
      this.yearCopyTargets.forEach((t) => t.classList.add("hidden"));
      this.yearUpsellCardTarget.classList.remove("selected");
    }
    if (this.hiddenInputTarget.value === "year") {
      this.monthCopyTargets.forEach((t) => t.classList.add("hidden"));
      this.yearCopyTargets.forEach((t) => t.classList.remove("hidden"));
      this.yearUpsellCardTarget.classList.add("selected");
      setTimeout(
        () => this.yearUpsellCardTarget.classList.remove("selected"),
        1500
      );
    }
  }
}
