import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabTitles", "tabItem"];

  connect() {
    this.tabTitlesTarget.addEventListener("click", (event) => {
      this.tabTitlesTarget.querySelectorAll("li").forEach((tabTitleEl) => {
        if (tabTitleEl === event.target) {
          tabTitleEl.classList.add("border-gray-900");
          tabTitleEl.classList.remove("text-gray-600");
        } else {
          tabTitleEl.classList.remove("border-gray-900");
          tabTitleEl.classList.add("text-gray-600");
        }
      });

      const tab = event.target.dataset.tab;
      this.tabItemTargets.forEach((tabItem) => {
        if (tabItem.dataset.tab === tab) {
          tabItem.classList.remove("hidden");
        } else {
          tabItem.classList.add("hidden");
        }
      });
    });
  }
}
