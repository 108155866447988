import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("sl-change", this.submitForm.bind(this));
    this.element.addEventListener("change", this.submitForm.bind(this));
  }

  submitForm() {
    if (typeof this.element.requestSubmit === "function") {
      this.element.requestSubmit();
    } else {
      this.element.submit();
    }
  }
}
