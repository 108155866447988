import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const hash = this.element.getAttribute("data-churnkey-hash");
    const customerId = this.element.getAttribute("data-stripe-customer-id");
    this.element.addEventListener("click", () => {
      if (typeof window.churnkey === "undefined") {
        window.location.href = "/members/cancel_backup";
      } else {
        window.churnkey
          .init("show", {
            customerId,
            authHash: hash,
            appId: "dt4d9nefc",
            mode:
              window.CONFIG.privsourceEnv === "production" ? "live" : "test",
            handleSupportRequest: function () {
              if (typeof Beacon === "undefined") {
                alert("Please email: danny@privsource.com for help");
              } else {
                Beacon("open");
              }
            },
          })
          .then(() => {
            console.log("Showed churnkey");
          })
          .catch((error) => {
            console.error("Error showing churnkey", error);
          });
      }
    });
  }
}
