import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {}

  showForm() {
    this.formTarget.classList.remove("hidden");
  }
}
