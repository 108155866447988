import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checklistSection"];

  connect() {}

  toggle(evt) {
    evt.preventDefault();
    const isHidden = this.checklistSectionTarget.classList.contains("hidden");
    if (isHidden) {
      this.checklistSectionTarget.classList.remove("hidden");
    } else {
      this.checklistSectionTarget.classList.add("hidden");
    }
  }
}
