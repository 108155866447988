import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "industrySummary",
    "industryFull",
    "detailsPanel",
    "plus",
    "minus",
  ];

  connect() {
    this.trackingProps = JSON.parse(this.element.getAttribute("data-props"));
  }

  openDetails() {
    if (this.hasIndustrySummaryTarget) {
      this.industrySummaryTarget.classList.add("hidden");
      this.industryFullTarget.classList.remove("hidden");
    }
    this.plusTarget.classList.add("hidden");
    this.minusTarget.classList.remove("hidden");
    this.detailsPanelTarget.classList.remove("h-0");
    this.detailsPanelTarget.classList.remove("opacity-0");
    this.detailsPanelTarget.classList.add("h-auto");
    this.detailsPanelTarget.classList.add("opacity-100");
    let eventName = "Viewed directory item";
    if (this.element.getAttribute("data-is-blurred")) {
      eventName = "Attempted view directory item";
    }
    ahoy.track(eventName, this.trackingProps);
  }

  closeDetails() {
    if (this.hasIndustrySummaryTarget) {
      this.industrySummaryTarget.classList.remove("hidden");
      this.industryFullTarget.classList.add("hidden");
    }
    this.plusTarget.classList.remove("hidden");
    this.minusTarget.classList.add("hidden");
    this.detailsPanelTarget.classList.add("h-0");
    this.detailsPanelTarget.classList.add("opacity-0");
    this.detailsPanelTarget.classList.remove("h-auto");
    this.detailsPanelTarget.classList.remove("opacity-100");
  }
}
