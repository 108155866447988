import { Controller } from "@hotwired/stimulus";
import { Track } from "../../app/track";

export default class extends Controller {
  static targets = ["paragraph", "show", "hide"];

  connect() {
    this._lineClampClasses = Array.from(this.paragraphTarget.classList).filter(
      (c) => {
        return c.startsWith("line-clamp");
      }
    );
    this.render();
  }

  show() {
    this._lineClampClasses.forEach((c) =>
      this.paragraphTarget.classList.remove(c)
    );
    this.showTarget.classList.add("hidden");
    this.hideTarget.classList.remove("hidden");
  }

  hide() {
    this._lineClampClasses.forEach((c) =>
      this.paragraphTarget.classList.add(c)
    );
    this.showTarget.classList.remove("hidden");
    this.hideTarget.classList.add("hidden");
  }

  render() {
    const { paragraphTarget: element } = this;
    if (
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    ) {
      this.showTarget.classList.remove("hidden");
    } else {
      this.showTarget.classList.add("hidden");
    }
  }
}
