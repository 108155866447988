import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["turboFrame"];

  connect() {
    this.element.addEventListener("sl-show", this.loadFrame.bind(this));
  }

  loadFrame() {
    this.turboFrameTarget.src = this.element.dataset.frameSrc;
  }
}
