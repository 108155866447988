import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "entityCheckbox",
    "selectAllCheckbox",
    "aiEntitiesBulkAddFrame",
    "container",
  ];

  connect() {
    this.entityIds = [];
  }

  toggleSelectAllCheckbox(event) {
    if (event.target.checked) {
      this.entityCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = true;
        this.addEntity(checkbox.value);
      });
    } else {
      this.entityCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = false;
        this.removeEntity(checkbox.value);
      });
    }
  }

  toggleSingleCheckbox(event) {
    if (event.target.checked) {
      this.addEntity(event.target.value);
    } else {
      this.removeEntity(event.target.value);
    }
  }

  addEntity(entityId) {
    this.entityIds.push(entityId);
    this.entityIds = [...new Set(this.entityIds)];
    this.updateAiEntitiesBulkAddFrame();
  }

  removeEntity(entityId) {
    this.entityIds = this.entityIds.filter((id) => id !== entityId);
    this.updateAiEntitiesBulkAddFrame();
  }

  updateAiEntitiesBulkAddFrame() {
    if (!this.hasAiEntitiesBulkAddFrameTarget) {
      console.warn(
        "[ProjectBulkAddsController: Missing aiEntitiesBulkAddFrameTarget"
      );
      return;
    }
    const q = new URLSearchParams();
    q.append("entity_nomenclature", this.element.dataset.entityNomenclature);
    this.entityIds.forEach((id) => q.append("ai_entity_ids[]", id));
    const url = `/seller/project_bulk_adds/ai_entities`;
    this.aiEntitiesBulkAddFrameTarget.src = `${url}?${q.toString()}`;
    if (this.entityIds.length > 0) {
      this.containerTarget.classList.remove("hidden");
    }
  }
}
