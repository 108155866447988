import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hidden", "input", "inlineError", "serverError"];

  connect() {
    if (typeof Stripe === "undefined") {
      this.inputTarget.classList.add("error");
      this.inputTarget.innerHTML =
        "Could not load Stripe.js. Check browser extensions.";
      return;
    }
    const stripe = Stripe(window.CONFIG.stripePk);
    const elements = stripe.elements();
    const cardElement = elements.create("card");
    cardElement.mount(this.inputTarget);
    cardElement.on("change", async (event) => {
      if (event.complete) {
        this.hiddenTarget.value = "";
        if (this.hasInlineErrorTarget) {
          this.inlineErrorTarget.innerHTML = "";
        }
        if (this.hasServerErrorTarget) {
          this.serverErrorTarget.innerHTML = "";
        }
        try {
          const { token, error } = await stripe.createToken(cardElement);
          if (token) {
            ahoy.track("Entered stripe card", { token_id: token.id });
            this.hiddenTarget.value = token.id;
            return;
          }
          if (error) {
            this.setInlineError(error.message);
          }
        } catch (e) {
          console.error(e);
          this.inlineErrorTarget.innerHTML = "Error creating payment token";
          ahoy.track("Error creating payment token", { error: e });
        }
      } else if (event.error) {
        this.setInlineError(event.error.message);
        ahoy.track("Error from stripe input change event", {
          error_message: event.error.message,
        });
      } else {
        let eventPayload = "";
        try {
          eventPayload = JSON.stringify(event);
        } catch (e) {
          eventPayload = "Error converting event to JSON";
        }
        ahoy.track("Entered info for stripe", { event_payload: eventPayload });
      }
    });
  }

  setInlineError(message) {
    this.serverErrorTarget.innerHTML = "";
    this.inlineErrorTarget.innerHTML = message;
  }

  useNew(evt) {
    this.element.classList.add("use-new");
    this.element.classList.remove("use-saved");
  }

  useSaved(evt) {
    this.element.classList.add("use-saved");
    this.element.classList.remove("use-new");
  }
}
