import "chosen-js";

export const setup = function () {
  $(".chosen").each((idx, el) => {
    const $el = $(el);
    if ($el.data("chosen")) {
      return;
    } else {
      $el.chosen({
        placeholder_text_multiple: el.getAttribute("placeholder") || "Search",
        allow_single_deselect: true,
      });
    }
  });
  setTimeout(() => {
    $(".chosen").trigger("chosen:updated");
  }, 100);
};

export const teardown = function () {
  $(".chosen-container").remove();
};
