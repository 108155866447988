const disable = (evt) => {
  const $form = $(evt.target).closest("form");
  if ($form) {
    const $input = $(evt.target).find("input:submit");
    if ($input) {
      $input.prop("disabled", true);
    }
  }
};

export const setup = function () {
  $(document).on("submit", "form", disable);
};
