import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["panel"];

  close(evt) {
    this.panelTarget.classList.add("opacity-0");
    this.panelTarget.classList.remove("opacity-100");
  }
}
