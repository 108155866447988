import { Controller } from "@hotwired/stimulus";

const LOOP = 5000;

export default class extends Controller {
  static targets = ["slate"];

  connect() {
    this.ct = 0;
    this.showSix();
    this.loop();
  }

  loop() {
    this.timeout = setTimeout(() => {
      this.showSix();
      this.loop();
    }, LOOP);
  }

  showSix() {
    if (this.ct >= this.slateTargets.length) {
      this.ct = 0;
    }
    this.slateTargets.forEach((slate, idx) => {
      if (idx === this.ct) {
        slate.classList.add("opacity-100");
        slate.classList.remove("opacity-0");
      } else {
        slate.classList.add("opacity-0");
        slate.classList.remove("opacity-100");
      }
    });
    this.ct += 1;
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
