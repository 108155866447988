import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "restoreArea"];

  connect() {
    this.inputTarget.addEventListener("keyup", () => this.showRestore());
    this.defaultBody = this.inputTarget.getAttribute("data-default-body");
    if (this.defaultBody !== this.inputTarget.value) {
      this.showRestore();
    }
  }

  restoreDefault() {
    this.inputTarget.value = this.inputTarget.getAttribute("data-default-body");
    this.hideRestore();
  }

  showRestore() {
    this.restoreAreaTarget.classList.remove("invisible");
  }

  hideRestore() {
    this.restoreAreaTarget.classList.add("invisible");
  }
}
