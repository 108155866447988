import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    this.showElement = this.showElement.bind(this);
    this.hideElement = this.hideElement.bind(this);

    window.addEventListener("keydown", this.showElement);
    window.addEventListener("keyup", this.hideElement);
  }

  showElement(event) {
    if (event.ctrlKey && event.key === "a") {
      this.element.classList.remove("hidden");
    }
  }

  hideElement(event) {
    if (!event.ctrlKey || event.key === "a") {
      this.element.classList.add("hidden");
    }
  }

  disconnect() {
    window.removeEventListener("keydown", this.showElement);
    window.removeEventListener("keyup", this.hideElement);
  }
}
