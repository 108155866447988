import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["backdrop", "panel"];

  connect() {
    this.position = this.element.getAttribute("data-modal-position");
    const modalId = this.element.getAttribute("data-modal");
    if (!modalId) {
      throw new Error(`Missing modalId in data-modal attribute`);
    }
    document
      .querySelectorAll(`[data-modal-trigger=${modalId}]`)
      .forEach((el) => {
        el.addEventListener("click", (evt) => {
          this.open();
          evt.preventDefault();
        });
      });

    const embedPlayer = this.element.querySelector("[data-cf-embed-player]");
    if (embedPlayer) {
      if (typeof Stream === "undefined") {
        console.error(
          `Stream is undefined for embedPlayer ${modalId}`,
          embedPlayer.src
        );
      } else {
        this.streamPlayer = Stream(embedPlayer);
      }
    }
    if (this.element.getAttribute("data-modal-start-open") === "true") {
      this.open();
    }
  }

  open() {
    if (this.position === "center") {
      this.element.classList.remove("invisible");
      this.panelTarget.classList.add("opacity-100");
      this.panelTarget.classList.remove("opacity-0");
    }
    if (this.position === "right") {
      this.element.classList.remove("invisible");
      this.panelTarget.classList.add("opacity-100");
      this.panelTarget.classList.remove("opacity-0");
      this.panelTarget.classList.remove("mr-[-100%]");
    }
    // setTimeout(() => this.isOpen = true, 0);
  }

  close(evt) {
    evt.preventDefault();
    if (this.position === "center") {
      this.panelTarget.classList.remove("opacity-100");
      this.panelTarget.classList.add("opacity-0");
    }
    if (this.position === "right") {
      this.panelTarget.classList.remove("opacity-100");
      this.panelTarget.classList.add("opacity-0");
      this.panelTarget.classList.add("mr-[-100%]");
    }

    this.panelTarget.addEventListener(
      "transitionend",
      () => {
        this.element.classList.add("invisible");
      },
      { once: true }
    );

    if (this.streamPlayer) {
      this.streamPlayer.pause();
    }
    // setTimeout(()=> this.isOpen = false, 0);
  }
}
