import { Controller } from "@hotwired/stimulus";
import { Track } from "../../app/track";

export default class extends Controller {
  static targets = [];

  track(evt) {
    const name = evt.target.name;
    Track.gtagEvent(`Completed form field ${name}`);
  }
}
