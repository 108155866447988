export const Track = {
  gtagEvent(name) {
    if (typeof gtag === "undefined") {
      console.warn("gtag is undefined", name);
      return;
    }
    gtag("event", name);
  },
  gtagConversion(props = {}) {
    if (typeof gtag === "undefined") {
      console.warn("gtag is undefined", props);
      return;
    }
    gtag("event", "conversion", props);
  },
  fbEvent(name) {
    if (typeof fbq === "undefined") {
      console.warn("fbq is undefined", name);
      return;
    }
    fbq("track", name);
  },
};
