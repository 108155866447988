import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const listStr = this.element.getAttribute("data-list");
    const src = JSON.parse(listStr);
    if (!src) return;
    if (!this.inputTarget) {
      console.warn("No inputTarget for autoComplete");
      return;
    }
    const config = {
      selector: () => this.inputTarget,
      placeHolder: this.inputTarget.getAttribute("placeholder"),
      data: {
        src,
      },
      resultItem: {
        highlight: true,
        class:
          "text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 hover:text-gray-900",
      },
      resultsList: {
        class:
          "origin-top-right absolute rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
      },
    };
    this.inputTarget.addEventListener("selection", (event) => {
      this.inputTarget.value = event.detail.selection.value;
    });
    const autoCompleteJS = new autoComplete(config);
  }
}
