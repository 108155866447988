import { Controller } from "@hotwired/stimulus";

class ShoelaceFormController extends Controller {
  connect() {
    this.element.addEventListener("sl-submit", this.submitForm.bind(this));

    this.element
      .closest("form")
      .addEventListener("turbo:submit-end", this.submitEnd.bind(this));
  }

  submitForm(e) {
    let form = this.element.closest("form");
    let submitter = this.element.querySelector("sl-button[submit]");
    if (submitter.disabled) return;
    submitter.loading = true;
    submitter.disabled = true;

    for (let entry of e.detail.formData) {
      let [k, v] = entry;
      let el = document.createElement("input");
      el.type = "hidden";
      el.name = k;
      el.value = v;
      el.deleteMeLater = true;
      form.append(el);
    }

    let el = document.createElement("input");
    el.name = "commit";
    el.type = "submit";
    el.value = submitter.innerHTML;
    el.style.display = "none";
    el.deleteMeLater = true;
    form.append(el);
    el.click();
  }

  submitEnd(e) {
    let submitter = e.target.querySelector("sl-button[submit]");

    if (submitter) {
      submitter.loading = false;
      submitter.disabled = false;
      this.reset(e.target);
    }
  }

  reset(form) {
    form
      .querySelector("sl-form")
      .getFormControls()
      .then((controls) => {
        for (let control of controls) {
          switch (control.tagName.toLowerCase()) {
            case "sl-checkbox":
            case "sl-radio":
              control.checked = false;
              break;

            default:
              control.value = "";
          }
        }
      });

    for (let control of form.querySelectorAll("input")) {
      if (control.deleteMeLater) control.remove();
    }
  }
}

export default ShoelaceFormController;
