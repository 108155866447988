import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = [
    "input",
    "hiddenInput",
    "helpPanel",
    "autoCompletePanel",
    "searchArea",
  ];

  connect() {
    useClickOutside(this, { element: this.searchAreaTarget });

    this.inputTarget.addEventListener("focus", () => {
      this.helpPanelTarget.classList.remove("hidden");
    });

    this.inputTarget.addEventListener("keydown", (evt) => {
      if (evt.key === "Enter") {
        this.addKeyword(this.currentQ);
      }
    });

    this.keywords = this.hiddenInputTarget.value.split(",");

    if (this.searchAreaTarget.dataset.searchOpts) {
      this.searchOpts = JSON.parse(this.searchAreaTarget.dataset.searchOpts);
    } else {
      console.warn("No search options found");
    }
  }

  get currentUl() {
    if (!this.autoCompletePanelTarget.classList.contains("hidden")) {
      return this.autoCompletePanelTarget.querySelector("ul");
    }
    if (!this.helpPanelTarget.classList.contains("hidden")) {
      return this.helpPanelTarget.querySelector("ul");
    }
  }

  clickOutside() {
    this.helpPanelTarget.classList.add("hidden");
    this.autoCompletePanelTarget.classList.add("hidden");
  }

  autoComplete(evt) {
    this.currentQ = evt.target.value;
    if (this.currentQ) {
      this.helpPanelTarget.classList.add("hidden");
      this.autoCompletePanelTarget.classList.remove("hidden");
      this.renderAutoComplete();
    } else {
      this.helpPanelTarget.classList.remove("hidden");
      this.autoCompletePanelTarget.classList.add("hidden");
    }
  }

  addKeywordClick(evt) {
    this.addKeyword(evt.target.innerText);
  }

  addKeyword(keyword) {
    if (this.keywords.includes(keyword)) {
      return;
    }
    this.keywords.push(keyword);
    this.hiddenInputTarget.value = this.keywords.join(",");
    this.submitForm();
  }

  removeKeyword(evt) {
    evt.preventDefault();
    const keyword = evt.target.dataset.keyword;
    this.keywords = this.keywords.filter((kw) => kw !== keyword);
    this.hiddenInputTarget.value = this.keywords.join(",");
    this.submitForm();
  }

  matchesHasCurrentQuery(matches) {
    return matches
      .map((match) => match.toLowerCase())
      .includes(this.currentQ.toLowerCase());
  }

  renderAutoComplete() {
    const ul = this.autoCompletePanelTarget.querySelector("ul");
    const matches = this.searchOpts.filter((opt) => {
      const regex = new RegExp(`^${this.currentQ}`, "gi");
      return opt.match(regex);
    });
    ul.innerHTML = "";
    if (!this.matchesHasCurrentQuery(matches)) {
      matches.unshift(this.currentQ);
    }
    matches.forEach((match) => {
      const li = document.createElement("li");
      li.classList.add("cursor-pointer", "hover:bg-gray-100", "px-4", "py-2");
      li.innerHTML = match;
      ul.appendChild(li);
    });
  }

  submitForm() {
    this.hiddenInputTarget.form.submit();
  }
}
