import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  copyToClipboard() {
    const textarea = document.createElement("textarea");
    textarea.value = this.element.getAttribute("data-text");
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    const _prevText = this.element.innerText;
    this.element.innerText = "copied";
    textarea.remove();
    setTimeout(() => {
      if (this.element) {
        this.element.innerText = _prevText;
      }
    }, 4000);
  }
}
