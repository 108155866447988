import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["iframe", "chapterMarker"];

  connect() {
    if (this.streamPlayer) {
      this.streamPlayer.addEventListener("timeupdate", this.handleTimeUpdate);
    }
    document.addEventListener("sl-hide", this.handleModalHide);
  }

  disconnect() {
    if (this.streamPlayer) {
      this.streamPlayer.removeEventListener(
        "timeupdate",
        this.handleTimeUpdate
      );
    }
    document.removeEventListener("sl-hide", this.handleModalHide);
  }

  handleModalHide = (event) => {
    if (event.target.contains(this.iframeTarget)) {
      if (!this.streamPlayer) {
        console.error("Missing streamPlayer in hnadleModalHide");
        return;
      }
      this.streamPlayer.pause();
    }
  };

  handleTimeUpdate = (event) => {
    const currentTime = this.streamPlayer && this.streamPlayer.currentTime;
    if (!currentTime) {
      console.error("Missing currentTime");
      return;
    }
    let current = null;
    [...this.chapterMarkerTargets].forEach((chapterMarker) => {
      const { videoEmbedTimeParam } = chapterMarker.dataset;
      if (!videoEmbedTimeParam) {
        console.error("Missing videoEmbedTimeParam");
        return;
      }
      const timeSec = parseFloat(videoEmbedTimeParam);
      if (timeSec) {
        if (timeSec <= currentTime) {
          current = chapterMarker;
        }
      }
      chapterMarker.classList.remove("current");
    });
    if (current) {
      current.classList.add("current");
    }
  };

  setTime(event) {
    const { params } = event;
    const { time } = params;
    if (!time) {
      console.error("Missing time parameter");
      return;
    }
    const player = this.streamPlayer;
    if (player) {
      player.currentTime = time;
      player
        .play()
        .then(() => {
          console.log("Playing");
        })
        .catch((error) => {
          console.error("Error playing", error);
        });
    }
  }

  // https://community.cloudflare.com/t/stream-player-api-javascript-play-and-pause-methods-not-working/432195/5
  // weird ass bug
  // because of this we keep a global reference to every stream player inside window.STREAM_HACKS
  //
  // ideally we'd be able to do a simple `new Stream(this.iframeTarget)`, but when we do
  // that the instead of streamPlayer doesn't work for calling play(), pause() or currentTime=
  //
  get streamPlayer() {
    if (typeof window.Stream === "undefined") {
      console.error("Stream is undefined");
      return null;
    }
    if (!this.hasIframeTarget) {
      console.error("Missing streamPlayerTarget");
      return null;
    }

    const streamPlayer = (window.STREAMS_HACK || {})[
      this.iframeTarget.getAttribute("id")
    ];
    if (!streamPlayer) {
      console.error("Stream player not found in STREAMS_HACK");
      return null;
    }
    return streamPlayer;
  }
}
