import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["state", "stepsJson"];

  connect() {
    this.handleResize = debounce(this.handleResize.bind(this), 250);
    window.addEventListener("resize", this.handleResize);
    this.tourActive = false;
    this.tourSuspended = false;

    this.__initialized = true;
    this.setState();
  }

  get startDelay() {
    return 600;
  }

  get TOUR_STEPS() {
    return JSON.parse(this.stepsJsonTarget.innerHTML);
  }

  get currentStep() {
    return this.TOUR_STEPS.find((step) => step.id === this.state_d.currentStep);
  }

  setState() {
    if (this.hasStateTarget) {
      this.state_d = JSON.parse(this.stateTarget.innerHTML);
      if (this.state_d.status === "completed") {
        this.endTour();
      } else {
        this.startTour();
      }
    } else {
      console.warn("No state target found");
    }
  }

  stateTargetConnected(element) {
    if (!this.__initialized) {
      console.warn("State target connected before controller initialized");
      return;
    }
    this.setState();
  }

  disconnect() {
    window.removeEventListener("resize", this.handleResize);
  }

  startTour() {
    if (this.isSmallScreen()) {
      console.log("Tour not available on small screens");
      return;
    }

    if (!this.currentStep) {
      console.warn("Step not found for state", this.state_d);
      return;
    }

    setTimeout(() => {
      this.tourActive = true;
      this.tourSuspended = false;
      this.currentStepNum = this.currentStep.id;
      this.showCurrentStep();
    }, this.startDelay);
  }

  showCurrentStep() {
    const step = this.currentStep;
    if (step.dialog_target) {
      this.hideOtherSteps();
      this.showDialogStep(step);
    } else if (step.popover_target) {
      this.hideOtherSteps();
      this.showPopoverStep(step);
    } else {
      console.warn("No target found for step", step);
    }
  }

  hideOtherSteps() {
    this.TOUR_STEPS.filter((step) => {
      return step.id != this.currentStepNum;
    }).forEach((step) => {
      if (step.dialog_target) {
        const el = this.dialogTargetIfPresent(step.dialog_target);
        if (el) {
          el.hide();
        } else {
          console.warn("Dialog not found for step", step);
        }
      }
      if (step.popover_target) {
        this.cleanupTourElements();
      }
    });
  }

  showDialogStep(step) {
    if (!step.dialog_target) {
      console.warn("No dialogTarget found for step", step);
      return;
    }
    const dialog = this.dialogTargetIfPresent(step.dialog_target);
    if (!dialog) {
      console.warn("Dialog not found for step", step);
      return;
    }
    dialog.show();
    dialog.addEventListener("sl-request-close", this.preventDialogClose);
  }

  preventDialogClose = (event) => {
    if (["keyboard", "overlay"].includes(event.detail.source)) {
      event.preventDefault();
    }
  };

  showPopoverStep(step) {
    if (!step.popover_target) {
      console.warn("No popoverTarget found for step", step);
      return;
    }
    if (!step.highlight_target) {
      console.warn("No highlightTarget found for step", step);
      return;
    }
    const popoverContent = this.popoverTargetIfPresent(step.popover_target);
    if (!popoverContent) {
      console.warn("Popover not found for step", step);
      return;
    }
    const highlightTarget = this.highlightTargetIfPresent(
      step.highlight_target
    );
    if (!highlightTarget) {
      console.warn("Highlight target not found for step", step);
      return;
    }
    this.updateTourElements();
  }

  handleResize() {
    if (this.isSmallScreen()) {
      if (this.tourActive) {
        this.suspendTour();
      }
    } else {
      if (this.tourSuspended) {
        this.resumeTour();
      } else if (this.tourActive) {
        this.updateTourElements();
      }
    }
  }

  isSmallScreen() {
    return window.innerWidth < 768; // Adjust this breakpoint as needed
  }

  suspendTour() {
    this.cleanupTourElements();
    this.tourSuspended = true;
  }

  resumeTour() {
    this.tourSuspended = false;
    this.tourActive = true;
  }

  updateTourElements() {
    if (!this.backdrop) {
      this.createBackdrop();
    }
    this.updateBackdropClip();
    if (!this.popover) {
      this.createPopover();
    }
    this.updatePopoverContent();
    this.updatePopoverPosition();
    this.updateHighlightPosition();
  }

  createBackdrop() {
    this.backdrop = document.createElement("div");
    this.backdrop.classList.add(
      "fixed",
      "inset-0",
      "bg-black",
      "bg-opacity-50",
      "z-40"
    );
    document.body.appendChild(this.backdrop);
  }

  updateBackdropClip() {
    const currentTarget = this.getCurrentHighlightTarget();
    if (!currentTarget) {
      console.warn("No current highlight target found for popover position");
      return;
    }
    const rect = currentTarget.getBoundingClientRect();
    this.backdrop.style.clipPath = `polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, ${rect.right}px 0%, ${rect.right}px ${rect.bottom}px, ${rect.left}px ${rect.bottom}px, ${rect.left}px ${rect.top}px, ${rect.right}px ${rect.top}px, ${rect.right}px 0%)`;
  }

  createPopover() {
    this.popover = document.createElement("div");
    this.popover.classList.add("fixed", "z-50", "max-w-sm");
    document.body.appendChild(this.popover);
  }

  updatePopoverContent() {
    if (!this.currentStep.popover_target) {
      console.warn("No popoverTarget found for current step", this.currentStep);
      return;
    }
    if (this.currentStep.popover_target) {
      const popoverContent = document.querySelector(
        `[data-ui-tour-popover="${this.currentStep.popover_target}"]`
      );
      if (!popoverContent) {
        console.warn(
          "Popover content not found for current step",
          this.currentStep
        );
        return;
      }
      this.popover.innerHTML = popoverContent.innerHTML;
    }
  }

  updatePopoverPosition() {
    const currentTarget = this.getCurrentHighlightTarget();
    if (!currentTarget) {
      console.warn("No current highlight target found for popover position");
      return;
    }
    const rect = currentTarget.getBoundingClientRect();
    this.popover.style.top = `${rect.bottom + 10}px`;
    this.popover.style.left = `${rect.left}px`;
  }

  highlightCurrentTarget() {
    const currentTarget = this.getCurrentHighlightTarget();
    if (!currentTarget) {
      console.warn("No current highlight target found for popover position");
      return;
    }
    this.highlightedElement = currentTarget.cloneNode(true);
    this.highlightedElement.classList.add(
      "fixed",
      "z-50",
      "ring-2",
      "ring-blue-500",
      "bg-white"
    );
    document.body.appendChild(this.highlightedElement);
    this.updateHighlightPosition();
  }

  updateHighlightPosition() {
    const currentTarget = this.getCurrentHighlightTarget();
    if (!currentTarget) {
      console.warn("No current highlight target found for highlight position");
      return;
    }
    const rect = currentTarget.getBoundingClientRect();
    if (this.highlightedElement) {
      this.highlightedElement.style.top = `${rect.top}px`;
      this.highlightedElement.style.left = `${rect.left}px`;
      this.highlightedElement.style.width = `${rect.width}px`;
      this.highlightedElement.style.height = `${rect.height}px`;
    }
    if (this.popover) {
      this.popover.style.top = `${rect.bottom + 10}px`;
      this.popover.style.left = `${rect.left}px`;
    }
  }

  dialogTargetIfPresent(targetName) {
    return document.querySelector(`[data-ui-tour-dialog="${targetName}"]`);
  }

  highlightTargetIfPresent(targetName) {
    return document.querySelector(`[data-ui-tour-highlight="${targetName}"]`);
  }

  popoverTargetIfPresent(targetName) {
    return document.querySelector(`[data-ui-tour-popover="${targetName}"]`);
  }

  getCurrentHighlightTarget() {
    const step = this.currentStep;
    if (step && step.highlight_target) {
      return this.highlightTargetIfPresent(step.highlight_target);
    } else {
      console.warn("No highlightTarget found for current step", step);
    }
  }

  cleanupTourElements() {
    if (this.backdrop) this.backdrop.remove();
    if (this.popover) this.popover.remove();
    if (this.highlightedElement) this.highlightedElement.remove();
    if (this.hasWelcomeDialogTarget) this.welcomeDialogTarget.hide();
    this.backdrop = null;
    this.popover = null;
    this.highlightedElement = null;
  }

  endTour() {
    this.cleanupTourElements();
    this.tourActive = false;
    this.tourSuspended = false;
  }
}
